var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"analitycsTargetsModal",attrs:{"id":"analitycs-targets-modal","hide-header":"","hide-footer":"","centered":""},on:{"hidden":_vm.checkReload,"show":_vm.resetModelChanges}},[_c('div',{staticClass:"analitycs-targets-modal-content"},[_c('div',{staticClass:"analitycs-targets-modal-header"},[_c('h1',{staticClass:"title"},[_vm._v(" Analytics ")]),_c('div',{staticClass:"closer",on:{"click":_vm.closeModal}},[_vm._v(" X ")])]),_c('validation-observer',{ref:"analyticsObserverTabs"},[_c('b-tabs',{attrs:{"fill":""}},_vm._l((_vm.tabsData),function(tabData,index){return _c('b-tab',{key:index,attrs:{"title":tabData.title,"active":tabData.active},on:{"click":function($event){return _vm.loadTabContent(index)}}},[(!tabData.loading)?_c('div',{staticClass:"tab-content"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"input-group-wrapper"},[_c('h1',{staticClass:"tab-content-title"},[_vm._v(" Customers ")]),_c('validation-provider',{attrs:{"name":"Customers","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"0"},model:{value:(tabData.formData.customers),callback:function ($$v) {_vm.$set(tabData.formData, "customers", $$v)},expression:"tabData.formData.customers"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"input-group-wrapper"},[_c('h1',{staticClass:"tab-content-title"},[_vm._v(" Shopify customers ")]),_c('validation-provider',{attrs:{"name":"shopify customers","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"0"},model:{value:(tabData.formData.buyers),callback:function ($$v) {_vm.$set(tabData.formData, "buyers", $$v)},expression:"tabData.formData.buyers"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"input-group-wrapper"},[_c('h1',{staticClass:"tab-content-title"},[_vm._v(" App users ")]),_c('validation-provider',{attrs:{"name":"App users","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"0"},model:{value:(tabData.formData.app_users),callback:function ($$v) {_vm.$set(tabData.formData, "app_users", $$v)},expression:"tabData.formData.app_users"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"input-group-wrapper"},[_c('h1',{staticClass:"tab-content-title"},[_vm._v(" Rides ")]),_c('validation-provider',{attrs:{"name":"Rides","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"0"},model:{value:(tabData.formData.rides),callback:function ($$v) {_vm.$set(tabData.formData, "rides", $$v)},expression:"tabData.formData.rides"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"input-group-wrapper"},[_c('h1',{staticClass:"tab-content-title"},[_vm._v(" Odometer ")]),_c('validation-provider',{attrs:{"name":"Odometer","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"0"},model:{value:(tabData.formData.odometer),callback:function ($$v) {_vm.$set(tabData.formData, "odometer", $$v)},expression:"tabData.formData.odometer"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"input-group-wrapper"},[_c('h1',{staticClass:"tab-content-title"},[_vm._v(" Scooters ")]),_c('validation-provider',{attrs:{"name":"Scooters","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"0"},model:{value:(tabData.formData.scooters),callback:function ($$v) {_vm.$set(tabData.formData, "scooters", $$v)},expression:"tabData.formData.scooters"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"input-group-wrapper"},[_c('h1',{staticClass:"tab-content-title"},[_vm._v(" Rides mileage ")]),_c('validation-provider',{attrs:{"name":"Rides mileage","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"0"},model:{value:(tabData.formData.rides_mileage),callback:function ($$v) {_vm.$set(tabData.formData, "rides_mileage", $$v)},expression:"tabData.formData.rides_mileage"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"save-modal-button"},[_c('input',{staticClass:"btn save-button",attrs:{"type":"submit","value":"Save"},on:{"click":_vm.saveTabContent}})])])],1):_c('div',{staticClass:"mt-1 loader-div"},[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1)],1)])}),1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }