<template>
  <div class="customers-sales-charts">
    <b-row class="justify-content-center">
      <div class="justify-content-left w-100 pr-1">
        <a
          :href="chartData.store_link"
          target="_blank"
        ><img
          class="float-right"
          src="@/assets/images/icons/general-icons/chart-link-icon.png"
        ></a>
      </div>
      <vue-apex-charts
        type="radialBar"
        :height="height"
        class="customer-chart"
        :options="RadialBar.chartOptions"
        :series="RadialBar.chartOptions.series"
      />
    </b-row>
    <b-col />

    <b-col
      cols="12"
      class="statistics-data-container"
    >

      <b-row class="statistics-row">
        <b-col
          cols="12"
          class="statistics-column"
        >
          <div class="date-text">
            Yesterday
          </div>
          <div
            class="amount-text"
            :class="chartData.yesterdayTargetClass"
          >
            {{ chartData.yesterdaySales }} / {{ chartData.dailyTarget }}
          </div>
        </b-col>
      </b-row>

      <b-row class="statistics-row">
        <b-col
          cols="12"
          class="statistics-column"
        >
          <div class="date-text">
            Month
          </div>
          <div
            class="amount-text"
            :class="chartData.monthTargetClass"
          >
            {{ chartData.monthSales }} / {{ chartData.monthTarget }}
          </div>
        </b-col>
      </b-row>

      <b-row class="statistics-row">
        <b-col
          cols="12"
          class="statistics-column"
          :class="chartData.yearTargetClass"
        >
          <div class="date-text">
            YTD
          </div>
          <div class="amount-text">
            {{ chartData.yearSales }}
          </div>
        </b-col>
      </b-row>
      <!-- <b-row class="statistics-row">
        <b-col
          cols="12"
          class="statistics-column"
          :class="chartData.yearTargetClass"
        >
          <div class="date-text">
            YTD
          </div>
          <div
            class="amount-text"
            :class="chartData.yearTargetClass"
          >
            {{ chartData.yearSales }} / {{ chartData.yearTarget }}
          </div>
        </b-col>
      </b-row> -->
    </b-col>

  </div>
</template>

<script>

import VueApexCharts from 'vue-apexcharts'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  components: {
    VueApexCharts,
    BRow,
    BCol,
  },
  props: {
    chartData: {
      type: Object,
      default() {
        return null
      },
    },
    customTotal: {
      type: Boolean,
      default() {
        return false
      },
    },
    height: {
      type: Number,
      default() {
        return 160
      },
    },
  },
  data() {
    return {
      RadialBar: {
        chartOptions: {
          series: [this.chartData.dailySalesPercentage, this.chartData.monthSalesPercentage],
          labels: this.chartData.labels,
          colors: this.chartData.colors,
          customTotal: this.customTotal ? this.customTotal : null,
          targets: this.chartData.targets,
          targets_labels: this.chartData.targetsLabels,
          currentMonth: this.chartData.currentMonth,
          plotOptions: {

            radialBar: {
              size: 500,
              startAngle: -180,
              endAngle: 180,
              hollow: {
                image: require(`@/assets/images/icons/${this.chartData.icon}`),
                margin: 25,
                size: '65%',
                imageWidth: 20,
                imageHeight: 20,
                imageClipped: false,
                imageOffsetX: 0,
                imageOffsetY: -45,
              },
              track: {
                strokeWidth: '100%',
                fontSize: '18px',
              },
              dataLabels: {
                value: {
                  fontSize: '18px',
                  colors: '#1d1d1d',
                  fontWeight: '600',
                  LineHeight: '22px',
                  offsetY: 15,
                  formatter(value) {
                    return `${parseFloat(value).toFixed(2)}%`
                  },
                },
                total: {
                  show: true,
                  label: this.chartData.monthSales,
                  fontSize: '26px',
                  colors: '#1d1d1d',
                  fontWeight: '700',
                  LineHeight: '32px',
                  offsetY: 10,
                  formatter(value) {
                    return `${value.config.series[0].toFixed(2)}%`
                  },
                },
              },
            },
          },

          tooltip: {
            enabled: true,
            style: {
              fontSize: '15px',
              fontColor: '#ffffff',
            },

            custom: this.customTotal ? function ({
              seriesIndex, dataPointIndex, w,
            }) {
              return `<div class="tooltip-wrapper text-center text-white" style="background-color:#000">
                        <div class="tooltip-title font-weight-bolder">${w.config.targets_labels[seriesIndex]}</div>
                          <div class="tooltip-data">${w.config.labels[seriesIndex]} from ${w.config.targets[seriesIndex]}</div>
                             <div class="tooltip-data-month">${w.config.currentMonth}</div>
                             </div>
                             <style>
                                  .customer-chart .apexcharts-tooltip.apexcharts-theme-light{
                                        border:none !important;
                                        background: transparent;
                                        box-shadow: none;
                                        font-size: 12px;
                                        padding:5px;

                                  }
                                  .customer-chart .apexcharts-tooltip.apexcharts-theme-light::after {
                                      content: "";
                                      position: sticky;
                                      top: 100%;
                                      left: 50%;
                                      width: 0;
                                      height: 0;
                                      border-style: solid;
                                      border-color: black transparent transparent transparent;
                                  }
                                  .customer-chart .tooltip-wrapper {
                                      padding:5px;
                                  }
                                  .customer-chart .percentage-sign {
                                      font-size: 11px;
                                      margin-left: 1px;
                                  }
                                </style>`
            } : null,
          },
          stroke: {
            lineCap: 'round',
          },
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },

          responsive: [
            {
              breakpoint: 1400,
              options: {
                chart: {
                  height: 180,
                },

                dataLabels: {
                  value: {
                    fontSize: '10px',
                    colors: '#1d1d1d',
                    fontWeight: '600',
                    LineHeight: '22px',

                  },
                  total: {
                    fontSize: '5px',
                    colors: '#1d1d1d',
                    fontWeight: '700',
                    LineHeight: '32px',
                  },
                },

              },

            },

            {
              breakpoint: 1000,
              options: {
                chart: {
                  height: 200,
                },
              },
            },
            {
              breakpoint: 770,
              options: {
                chart: {
                  height: 300,
                },
              },
            },
          ],
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>

.customers-charts {
  min-height: 436px;
}

    .lagend-data-container {
        margin: 10px;
        .lagend-wrapper {
          padding:0px 12px !important;
        }
        .date-text{
          margin-right: 10px;
          margin-bottom:10px;
          font-weight:400;
          font-size: 10px;
          line-height:12.19px;
        }
    }

    .apexcharts-tooltip-series-group {
        color: #ffffff !important;
    }

    .circle-span {
        height: 15px;
        width: 15px;
        border:2px solid #000000;
        margin-right: 15px;
        border-radius: 50%;
    }

    .list-item {
        width: 100%;
    }

    .list-items-container {
        width:100% !important;
    }

    .no-horizon-margin {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .statistics-column {
        padding-right:0px;
        padding-left: 0px;
    }

    .date-text {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color:#808080;
    }

    .statistics-row {
        margin-bottom: 14px;
      .amount-text {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color:#1D1D1D;
      }
      .sign-text {
        font-size: 12px;
      }
    }

    .lagent-data-container {
        margin-bottom:5px;
    }
    .lagend-wrapper{
        .legend-pollet {
          width:6px;
          height: 6px;
          background-color: gray;
          display: block;
          float:left;
          margin-top:7px;
          margin-right:5px;
          border-radius: 50%;
      }
      .legend-text {
          font-size: 12px;
      }
    }
    @media screen and (min-width: 1450px){
        .statistics-column {
            padding-right:14px !important;
            padding-left: 14px !important;
            .date-text{
             font-size: 14px !important;
           }
        }
    }
    @media screen and (max-width: 1440px){
        .lagend-data-container{
          .lagend-wrapper {
            padding:0px !important;
          }
        }
        .statistics-row {
          margin-bottom: 5px;
          .amount-text{
            font-size: 14px !important;
          }
          .date-text{
              font-size: 12px !important;
          }
        }
    }

    @media screen and (max-width: 767px){
          .statistics-row {
            .amount-text{
              font-size: 15px !important;
          }
          .date-text{
              font-size: 13px !important;
          }
        }
    }

</style>
