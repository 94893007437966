<template>
  <div class="stats">

    <div class="hero-title">
      Customer stats
    </div>
    <b-row class="statistics-panels">
      <b-col
        cols="6"
        class="main-title"
      >
        Customers
        <ul class="customers-states-list list-unstyled">
          <li
            class="stats-item usa-stats"
            :class="users ? 'users' : ''"
          >
            <i class="stats-icon usa-icon" />
            <div class="stats-title">
              USA
            </div>
            <div class="stats-amount">
              {{  customersItems.usa.amount ? parseInt(customersItems.usa.amount, 0).toLocaleString() : 0}}
            </div>
          </li>

          <li
            class="stats-item canada-stats"
            :class="users ? 'users' : ''"
          >
            <i class="stats-icon ca-icon" />
            <div class="stats-title">
              Canada
            </div>
            <div class="stats-amount">
              {{  customersItems.ca.amount ? parseInt(customersItems.ca.amount, 0).toLocaleString() : 0}}
            </div>
          </li>

          <li
            class="stats-item subs-stats d-none"
            :class="users ? 'users' : ''"
          >
            <i class="stats-icon subs-icon" />
            <div class="stats-title">
              Subscription
            </div>
            <div class="stats-amount">
              {{  customersItems.subs.amount ? parseInt(customersItems.subs.amount, 0).toLocaleString() : 0}}
            </div>
          </li>

        </ul>
      </b-col>

      <b-col
        cols="6"
        class="main-title"
      >
        Users by platform
        <ul class="customers-states-list list-unstyled">
          <li
            class="stats-item ios-stats"
            :class="users ? 'users' : ''"
          >
            <i class="stats-icon ios-icon" />
            <div class="stats-title">
              IOS users
            </div>
            <div class="stats-amount">
              {{  customersItems.ios.amount ? parseInt(customersItems.ios.amount, 0).toLocaleString() : 0}}
            </div>
          </li>

          <li
            class="stats-item android-stats"
            :class="users ? 'users' : ''"
          >
            <i class="stats-icon android-icon" />
            <div class="stats-title">
              Android users
            </div>
            <div class="stats-amount">
              {{  customersItems.android.amount ? parseInt(customersItems.android.amount, 0).toLocaleString() : 0}}
            </div>
          </li>
        </ul>

      </b-col>

    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    customersItems: {
      type: Object,
      default() {
        return {}
      },
    },
    users: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.stats {
    font-family: 'Montserrat';
    font-style: normal;
    color: #151210;
    line-height: 22px;
    .hero-title {
        font-weight: 600;
        font-size: 18px;
    }

    .statistics-panels {
        margin-top: 10px;
        .main-title {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
        }

        .customers-states-list {
            margin-top:10px;
            .stats-icon {
                display: block;
                width: 40px;
                height: 40px;
                background: #F3F3F3;
                border: 1px solid rgba(0, 0, 0, 0.04);
                border-radius: 8px;
                float: left;
                margin-right: 8px !important;
                margin-top:5px;
                background-repeat: no-repeat;
                background-position: center;
                color: #808080;
                margin-bottom: 15px;
                &.usa-icon {
                    background-image: url('~@/assets/images/icons/customer/usa.png') !important;
                }
                &.ca-icon {
                    background-image: url('~@/assets/images/icons/customer/ca.png') !important;
                }

                &.subs-icon {
                    background-image: url('~@/assets/images/icons/customer/hand.png') !important;
                }

                &.ios-icon {
                    background-image: url('~@/assets/images/icons/ride/apple.png') !important;
                }
                &.android-icon {
                    background-image: url('~@/assets/images/icons/ride/android-green.png') !important;
                }

            }

            .stats-item {
                overflow: hidden;
                .stats-title {
                    margin-top: 5px;
                    font-weight: 500;
                    font-size: 12px;
                    color: #9A8781;
                }
                .stats-amount {
                    margin-top: 3px;
                    font-weight: 600;
                    font-size: 18px;
                    color: #111;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                }

                &.users {
                  width: 100% !important;
                }

            }
    }

    }
}

</style>
