<template>
  <div>
    <b-modal
      id="analitycs-targets-modal"
      ref="analitycsTargetsModal"
      hide-header
      hide-footer
      centered
      @hidden="checkReload"
      @show="resetModelChanges"
    >
      <div class="analitycs-targets-modal-content">
        <div class="analitycs-targets-modal-header">
          <h1 class="title">
            Analytics
          </h1>
          <div
            class="closer"
            @click="closeModal"
          >
            X
          </div>
        </div>
        <validation-observer ref="analyticsObserverTabs">
          <b-tabs fill>
            <!-- BODY -->
            <b-tab
              v-for="(tabData, index) in tabsData"
              :key="index"
              :title="tabData.title"
              :active="tabData.active"
              @click="loadTabContent(index)"
            >
              <div
                v-if="!tabData.loading"
                class="tab-content"
              >
                <b-form
                  @submit.prevent
                >
                  <div class="input-group-wrapper">
                    <h1 class="tab-content-title">
                      Customers
                    </h1>

                    <validation-provider
                      #default="{ errors }"
                      name="Customers"
                      rules="required|integer"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="tabData.formData.customers"
                          placeholder="0"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div class="input-group-wrapper">
                    <h1 class="tab-content-title">
                      Shopify customers
                    </h1>

                    <validation-provider
                      #default="{ errors }"
                      name="shopify customers"
                      rules="required|integer"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="tabData.formData.buyers"
                          placeholder="0"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div class="input-group-wrapper">
                    <h1 class="tab-content-title">
                      App users
                    </h1>
                    <validation-provider
                      #default="{ errors }"
                      name="App users"
                      rules="required|integer"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="tabData.formData.app_users"
                          placeholder="0"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>

                  <div class="input-group-wrapper">
                    <h1 class="tab-content-title">
                      Rides
                    </h1>
                    <validation-provider
                      #default="{ errors }"
                      name="Rides"
                      rules="required|integer"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="tabData.formData.rides"
                          placeholder="0"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>

                  <div class="input-group-wrapper">
                    <h1 class="tab-content-title">
                      Odometer
                    </h1>
                    <validation-provider
                      #default="{ errors }"
                      name="Odometer"
                      rules="required|integer"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="tabData.formData.odometer"
                          placeholder="0"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>

                  <div class="input-group-wrapper">
                    <h1 class="tab-content-title">
                      Scooters
                    </h1>
                    <validation-provider
                      #default="{ errors }"
                      name="Scooters"
                      rules="required|integer"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="tabData.formData.scooters"
                          placeholder="0"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>

                  <div class="input-group-wrapper">
                    <h1 class="tab-content-title">
                      Rides mileage
                    </h1>
                    <validation-provider
                      #default="{ errors }"
                      name="Rides mileage"
                      rules="required|integer"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="tabData.formData.rides_mileage"
                          placeholder="0"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div class="save-modal-button">
                    <input
                      type="submit"
                      value="Save"
                      class="btn save-button"
                      @click="saveTabContent"
                    >
                  </div>
                </b-form>
              </div>

              <div
                v-else
                class="mt-1 loader-div"
              >
                <b-col class="d-flex justify-content-center">
                  <b-spinner label="Loading..." />
                </b-col>
              </div>

            </b-tab>
          </b-tabs>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal,
} from 'bootstrap-vue'
import moment from 'moment'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  integer,
} from '@validations'

export default {
  components: {
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    showAnalyticsModal: {
      type: Boolean,
      require: true,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      modelChanges: false,
      tabsData: [
        {
          title: moment().format('YYYY'),
          value: moment().format('YYYY'),
          active: true,
          formData: {
            customers: 0,
            buyers: 0,
            app_users: 0,
            odometer: 0,
            rides: 0,
            scooters: 0,
          },
          loading: true,
        },
        {
          title: moment().subtract(1, 'y').format('YYYY'),
          value: moment().subtract(1, 'y').format('YYYY'),
          active: false,
          formData: {
            app_users: 0,
            customers: 0,
            odometer: 0,
            rides: 0,
            buyers: 0,
          },
          loading: true,
        },
        {
          title: moment().subtract(2, 'y').format('YYYY'),
          value: moment().subtract(2, 'y').format('YYYY'),
          active: false,
          loading: true,
          formData: {
            app_users: 0,
            customers: 0,
            odometer: 0,
            rides: 0,
            buyers: 0,
          },
        },
      ],
    }
  },
  watch: {
    showAnalyticsModal() {
      this.$refs.analitycsTargetsModal.show()
    },
  },
  mounted() {
    this.modelChanges = false
    this.loadTabContent(0)
  },

  methods: {
    resetModelChanges() {
      this.modelChanges = false
    },
    checkReload() {
      if (this.modelChanges) this.$emit('reloadAnalitycsTargetsCharts')
    },
    closeModal() {
      this.$refs.analitycsTargetsModal.hide()
    },
    loadTabContent(index) {
      this.tabsData[index].loading = true
      this.$http.get(this.$resources.targets.analytics.list, {
        params: {
          year: this.tabsData[index].value,
        },
      }).then(response => {
        const { data: { data } } = response
        this.tabsData[index].formData = data
        this.removeTabActiveState().then(nData => {
          this.tabsData = nData
          this.tabsData[index].active = true
          this.tabsData[index].loading = false
        })
      })
    },
    async removeTabActiveState() {
      return new Promise((resolve, _reject) => {
        Object.keys(this.tabsData).forEach(i => { this.tabsData[i].active = false })
        return resolve(this.tabsData)
      })
    },
    saveTabContent() {
      const index = this.tabsData.map(tabItem => tabItem.active).indexOf(true)
      this.$refs.analyticsObserverTabs.validate()
        .then(success => {
          if (success) {
            this.tabsData[index].loading = true
            const newData = Object.entries(this.tabsData[index].formData).map(dataItem => ({ type: dataItem[0], amount: dataItem[1] }))
            this.$http.put(this.$resources.targets.analytics.update, {
              year: this.tabsData[index].value,
              targets: newData,
            }).then(() => {
              this.tabsData[index].loading = false
            })
            this.modelChanges = true
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
#analitycs-targets-modal {
  .analitycs-targets-modal-content {
    min-height: 410px;
    padding: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        font-size: #1D1D1D;
        margin-bottom: 10px;
    }
    &::v-deep {
        .nav-fill {
            background-color: #F5F3F2;
            width: 100%;
            display: inline-flex;
            li {
                display: flex;
                justify-content: space-evenly;
                a {
                border-radius: 10px;
                border: 1px solid transparent;
                font-weight: 500;
                width: 120px;
                &.active {
                    background-color:#E6E6E6;
                    border: 1px solid rgba(48, 48, 48, 0.04);
                }
             }
           }
        }
      }

    .tab-content {
      .tab-pane {
        .input-group-wrapper {
                    overflow: hidden;
                    margin-bottom: 5px;
                    .tab-content-title {
                        padding-left: 3px;
                        font-weight: 500;
                        font-size: 14px;
                        color:#1D1D1D;
                        margin-bottom: 3px;
                    }
                }
                input {
                    color: #1D1D1D;
                    font-weight: 500;
                }

            }
    }

    .loader-div {
      display: flex;
      align-items: center;
      height: 340px;
    }
    .save-modal-button {
        display: flex;
        justify-content: flex-end;
        margin: 12px 0 0 0;
        .save-button {
            padding: 8px 18px;
            background: #E7E7E7;
            color: #1D1D1D;
            border-radius: 5px;
            float: right;
            &:hover {
                padding: 8px 18px;
                background-color:lightgray;
                border-radius: 5px;
                float: right;
            }
        }
    }

    .analitycs-targets-modal-header {
        display: flex;
        justify-content: space-between;
        .closer {
            cursor: pointer;
            font-family: cursive;
            font-weight: 500;
            font-size: 17px;
            color: #C6C6C6;
            width: 20px;
            text-align: center;
            height: 24px;
            &:hover {
                color: #68627C
            }
        }
    }

  }
}
</style>
