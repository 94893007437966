<!-- this components displayed on the home page TAKECARE -->
<template>
  <div>
    <b-card class="stats-list-home-holder">
      <b-row>
        <b-col>
          <new-table
            :export-file-name="exportFileName"
            :columns="columns"
            :items="items"
            table-name="stats-list-home"
            :custom-time-range="false"
            :selecte-time="selecteTime"
            :loading="loadTableDataProgress"
            @loadTableData="load"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, VBTooltip, BCol, BRow,
} from 'bootstrap-vue'
import NewTable from '@/views/new-table.vue'
import moment from 'moment'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    NewTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      moment,
      exportFileName: `stats-list-home-${moment(new Date()).format('YYYY-MM-DD')}`,
      loadTableDataProgress: false,
      selecteTime: null,
      oldFilter: '',
      items: [],
      scooterStatsLoading: true,
      statsLoading: true,
      fields: [
        { key: 'scooter_type', label: 'Scooter Type', sortable: true },
        { key: 'scooters_count', label: 'Quantity', sortable: true },
        { key: 'rides_count', label: 'Total Rides', sortable: true },
        { key: 'avg_distance', label: 'avg. Distance', sortable: true },
        { key: 'avg_distance_full_battery', label: 'Distance Full Battery', sortable: true },
        { key: 'avg_max_speed', label: 'avg. Max Speed', sortable: true },
        { key: 'avg_odometer', label: 'avg. Odometer', sortable: true },
        { key: 'avg_battery_consumption', label: 'avg. Battery Consumption', sortable: true },
        { key: 'avg_max_motor_temperature', label: 'avg. Motor Temperature', sortable: true },
        { key: 'avg_max_controller_temperature', label: 'avg. Controller Temperature', sortable: true },
      ],
      columns: [
        {
          label: 'Scooter Type',
          data: 'scooter_type',
        },
        {
          label: 'Quantity',
          data: 'scooters_count',
        },
        {
          label: 'Total Rides',
          data: 'rides_count',
        },
        {
          label: 'Distance Full Battery',
          data: 'avg_distance_full_battery',
        },
        {
          label: 'avg. Distance',
          data: 'avg_distance',
        },
        {
          label: 'avg. Speed',
          data: 'avg_speed',
        },
        {
          label: 'avg. Max Speed',
          data: 'avg_max_speed',
        },
        {
          label: 'avg. Odometer',
          data: 'avg_odometer',
        },
        {
          label: 'avg. Battery Consumption',
          data: 'avg_battery_consumption',
        },
        {
          label: 'avg. Max Motor Temp',
          data: 'avg_max_motor_temperature',
        },
        {
          label: 'avg. Max Controller Temp',
          data: 'avg_max_controller_temperature',
        },
      ],
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: 0,
          subtitle: 'Month to day',
          customClass: 'mb-2 mb-xl-0',
          svg_img: true,
          svg_img_src: 'scooter-32.svg',
          svg_color: '',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: 0,
          subtitle: 'Year to day',
          customClass: 'mb-2 mb-xl-0',
          svg_img: true,
          svg_img_src: 'scooter-32.svg',
          svg_color: '',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: 0,
          subtitle: 'IOS',
          customClass: 'mb-2 mb-sm-0',
          svg_img: true,
          svg_img_src: 'scooter-32.svg',
          svg_color: '',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: 0,
          subtitle: 'Android',
          customClass: '',
          svg_img: true,
          svg_img_src: 'scooter-32.svg',
          svg_color: '',
        },
      ],
    }
  },
  mounted() {
    this.load()
  },
  created() {
    // listen to event from app.vue page
    this.$eventHub.$on('selectedPageRangeChanged', this.load)
  },
  beforeDestroy() {
    // ignore to event from app.vue page
    this.$eventHub.$off('selectedPageRangeChanged', this.load)
  },
  methods: {
    view(item) {
      this.$router.push(`/rides/${item.id}`)
    },
    getPeramsData(period) {
      let isYearSelector = false
      let params = {}
      const type = 'scooter_avgs'
      if (typeof period === 'object') {
        params = period
        if (params) {
          params.type = type
        } else {
          params = { type }
        }
      } else {
        isYearSelector = true
        const start_date = moment(`01/01/${period}`).startOf('year').format('YYYY-MM-DD')
        const end_date = moment(`01/01/${period}`).endOf('year').format('YYYY-MM-DD')
        params = period !== 'all-time' ? { start_date, end_date, type } : null
      }
      return { params, isYearSelector }
    },
    load(showLoader = true, period = null, interval = null, dates = null, applyDates = false, type = 'both') {
      if (showLoader) {
        this.loadTableDataProgress = true
        this.selecteTime = period
      }

      const data = this.getPeramsData(period)
      this.$http.post(this.$resources.rides.calculations.charts, data.params).then(response => {
        const { data: { status, data } } = response
        if (status === 200) {
          this.items = data
          // this.items.unshift({
          //   avg_battery_consumption: "18.47",
          //   avg_distance: "5.63",
          //   avg_max_controller_temperature : "43.13",
          //   avg_max_motor_temperature: "42.17",
          //   avg_max_speed: "41.28",
          //   avg_odometer: "380.25",
          //   avg_speed: "22.44",
          //   color: "#FF9598",
          //   id: "96693a67-9916-11ee-9816-06408611f663",
          //   rides_count: 31633,
          //   scooter_type: "Total Average",
          //   scooters_count: 3954,
          // });
          this.loadTableDataProgress = false
        }
      })
    },
  },
}
</script>
<style>
.col-profit,.col-circle {
  max-width: 25% !important;
  flex:25% !important;
  clear: both !important;
}
.col-group {
  max-width: 50% !important;
  flex:50% !important;
  clear: both !important;
}

.stats-list-home-holder {
  min-height: 635px;
}
@media screen and (max-width: 1440px) {
  .col-profit,.col-circle {
    max-width: 50% !important;
    flex:50% !important;
    clear: both !important;
  }
  .col-group {
    max-width: 100% !important;
    flex:100% !important;
    clear: both !important;
  }
}
@media screen and (max-width: 720px) {
  .col-profit,.col-circle {
    max-width: 100% !important;
    flex:100% !important;
    clear: both !important;
  }
  .col-group {
    max-width: 100% !important;
    flex:100% !important;
    clear: both !important;
  }
}

</style>
