<template>
  <div class="stats">

    <div class="hero-title">
      Rides
    </div>
    <b-row class="statistics-panels">
      <b-col cols="6">
        <ul class="rides-states-list list-unstyled">
        <li
          class="stats-item usa-stats"
        >
          <i class="stats-icon trip-length" />
          <div class="stats-title">
            Average trip length
          </div>
          <div class="stats-amount">
            {{  ridesItems.avg_trip.amount ? `${parseInt(ridesItems.avg_trip.amount, 0).toFixed(2)}km` : 0}}
          </div>
        </li>


        <li
          class="stats-item canada-stats"
        >
          <i class="stats-icon mileage-icon" />
          <div class="stats-title">
            Mileage
          </div>
          <div class="stats-amount">
            {{  ridesItems.mileage.amount ? `${parseInt(ridesItems.mileage.amount, 0).toLocaleString()}km` : 0}}
          </div>
        </li>

        <li
          class="stats-item subs-stats"
        >
          <i class="stats-icon android-icon" />
          <div class="stats-title">
            Ride/User
          </div>
          <div class="stats-amount">
            {{ ridesItems.android.amount ? parseInt(ridesItems.android.amount, 0).toLocaleString() : 0}}
          </div>
        </li>



      </ul>
    </b-col>

    <b-col>
      <ul class="rides-states-list list-unstyled">
      <li
          class="stats-item subs-stats"
        >
          <i class="stats-icon trips-per-scooters" />
          <div class="stats-title">
            Trips per scooter
          </div>
          <div class="stats-amount">
            {{ ridesItems.trips_per_scooter.amount ? parseInt(ridesItems.trips_per_scooter.amount, 0).toLocaleString() : 0}}
          </div>
        </li>

        <li
          class="stats-item subs-stats"
        >
          <i class="stats-icon ios-icon" />
          <div class="stats-title">
            Ride/User
          </div>
          <div class="stats-amount">
            {{ ridesItems.ios.amount ? parseInt(ridesItems.ios.amount, 0).toLocaleString() : 0}}
          </div>
        </li>
      </ul>
    </b-col>



    </b-row>
    </div>
</template>
<script>
export default {
  props: {
    ridesItems: {
      type: Object,
      default() {
        return {}
      },

    },
  },
}
</script>
<style lang="scss" scoped>
.stats {
    font-family: 'Montserrat';
    font-style: normal;
    color: #151210;
    line-height: 22px;
    .hero-title {
        font-weight: 600;
        font-size: 18px;
    }

    .statistics-panels {
        margin-top: 10px;
        .main-title {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
        }

        .rides-states-list {
            margin:0px;
            margin-top:10px;
            .stats-icon {
                display: block;
                width: 40px;
                height: 40px;
                background: #F3F3F3;
                border: 1px solid rgba(0, 0, 0, 0.04);
                border-radius: 8px;
                float: left;
                margin-right: 8px !important;
                margin-top:5px;
                background-repeat: no-repeat;
                background-position: center;
                color: #808080;
                margin-bottom: 15px;
                &.usa-icon {
                    background-image: url('~@/assets/images/icons/customer/usa.png') !important;
                }
                &.ca-icon {
                    background-image: url('~@/assets/images/icons/customer/ca.png') !important;
                }

                &.subs-icon {
                    background-image: url('~@/assets/images/icons/customer/hand.png') !important;
                }

                &.ios-icon {
                    background-image: url('~@/assets/images/icons/ride/apple.png') !important;
                }
                &.android-icon {
                    background-image: url('~@/assets/images/icons/ride/android-green.png') !important;
                }

                &.mileage-icon {
                  background-image: url('~@/assets/images/icons/ride/mileage.png');
                }
                &.trip-length {
                  background-image: url('~@/assets/images/icons/ride/trip-length.png');
                }

                &.trips-per-scooters {
                  background-image: url('~@/assets/images/icons/ride/trips-per-scooters.png');
                }

            }

            .stats-item {
                overflow: hidden;
                .stats-title {
                    margin-top: 3px;
                    font-weight: 500;
                    font-size: 12px;
                    color: #9A8781;
                }
                .stats-amount {
                    font-weight: 600;
                    font-size: 18px;
                    color: #111;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                }

            }
      }

    }
}


</style>
