<template>
  <div
    class="chart-data"
  >
    <b-row class="widget-range-wrapper">
      <b-col
        sm="10"
        cols="9"
        class="analytics-title analytics-text"
      >
        Analytics
      </b-col>
      <b-col
        class="widget-range"
        cols="2"
      >
        <a
          class="edit-link"
          @click="$emit('showAnalyticsModal')"
        >Edit</a>


        <b-form-select
          v-if="showYearPicker"
          v-model="selectedRange"
          size="sm"
          :options="rangeOptions"
          @change="changeRangeItem"
        />

        <!-- <b-dropdown
          right
          :html="selectedRange"
        >
          <b-dropdown-item
            v-for="(item, index) in rangeOptions"
            :key="index"
            @click="changeRangeItem(item)"
          >
            {{ item.option }}
          </b-dropdown-item>

        </b-dropdown> -->
      </b-col>
    </b-row>
    <b-row
      v-if="dataLoaded"
      class="analytics-data"
    >
      <b-col cols="12">
        <ul class="list-unstyled">
          <li
            v-for="(series, index) in data"
            :key="index"
            class="bar-data"
            @mouseover="showPercentageFn(index)"
            @mouseleave="hidePercentageFn(index)"
          >
            <div class="class-wrapper">
              <div class="data">
                <div class="title">
                  {{ series.title }}
                </div>
                <div class="value-percentage">
                  <span
                    class="value"
                    :class="series.showPercentage ? 'd-none' : ''"
                  >
                    {{ series.value }}
                  </span>
                  <span
                    class="percentage"
                    :class="!series.showPercentage ? 'd-none' : ''"
                  >
                    {{ series.percentageValue }}
                  </span>
                </div>
              </div>
              <div
                class="percentage-span"
                :style="`width:${series.percentage}`"
              />
            </div>
          </li>
        </ul>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <div class="chart-loader">
          <b-spinner label="Loading..." />
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { BRow } from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BRow,
  },
  props: {
    data: {
      type: Array,
      default() {
        return []
      },
    },
    dataLoaded: {
      type: Boolean,
      default() {
        return false
      },
    },
    showYearPicker: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  data() {
    return {
      selectedRange: '',
      showPercentage: false,
      loading: false,
      rangeOptions: [
        {
          text: 'All time',
          value: null,
        },
        {
          text: moment().year(),
          value: moment().year(),
        },
        {
          text: moment().subtract(1, 'year').year(),
          value: moment().subtract(1, 'year').year(),
        },
        {
          text: moment().subtract(2, 'year').year(),
          value: moment().subtract(2, 'year').year(),
        },
      ],
    }
  },
  mounted() {
    this.selectedRange = null
  },
  methods: {
    changeRangeItem() {
      this.$emit('loadYearContent', this.selectedRange)
    },
    showPercentageFn(itemIndex) {
      if(this.data[itemIndex].hover) {
        this.data[itemIndex].showPercentage = true
      }
    },
    hidePercentageFn(itemIndex) {
      if(this.data[itemIndex].hover) {
        this.data[itemIndex].showPercentage = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  $border-radius-px: 4px;
  .chart-data {
    display: block !important;

    .edit-link {
      margin-left: 10px;
    }
  .chart-loader {
    min-height: 297px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

   .analytics-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #1D1D1D;
  }
    display: flex;
    font-weight: 500;
    font-size: 15px;
    .bar-data {
      cursor: pointer;
      background-color: #EEEEEE;
      position: relative;
      margin-top:10px;
      padding: 5px 8px;
      // height: 30px;
      height: 28px;
      border-radius: $border-radius-px;
      .percentage-span {
        left: 0;
        top: 0;
        height: 100%;
        position: absolute;
        background: #BCDBBC;
        border-radius: $border-radius-px;
      }
    }
    .class-wrapper {
      .data {
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        color: #1D1D1D;
        z-index: 1;
        position: sticky;
        display: flex;
        justify-content: space-between;
        .title {
          color: #1D1D1D;
        }
      }
    }

    .widget-range-wrapper {
      .edit-text {
        margin-top: 10px;
        text-align: center;
        a{
          display: block;
        }
      }
      .widget-range {
        margin-top: 0 !important;
        .dropdown  {
          border: #C6C6C6 1px solid !important;
          margin-top: -3px !important;
          padding-top: 6px;
          border-radius: 8px;
          width: 100%;
          float: right !important;
          margin-right: 10px;
          .dropdown-toggle {
            border-radius: 8px;
            width: 30% !important;
            color: #1D1D1D !important;
            margin-top: -8px !important;
            margin-bottom: -5px;
          }
        }
      }
    }

    .analytics-data {
      margin-top:17px !important;
      .col-12 {
        border-left: 0px !important;
      }
    }
  }

@media screen and (max-width: 1580px){
  .chart-data {
    .widget-range-wrapper{
      .widget-range {
        .dropdown {
           width: 120% !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1440px){
  .chart-data {
    .widget-range-wrapper{
        .widget-range {
          .dropdown {
            width: 160% !important;
          }
        }
      }
    }
}

@media screen and (max-width: 1000px){
  .chart-data {
  .widget-range-wrapper{
    margin-top: 0px !important;

      .widget-range {
        .dropdown {
           width: 100% !important;
        }
      }
    }
  }
}

@media screen and (max-width: 825px){
    .chart-data {
    .widget-range-wrapper{
        .widget-range {
          .dropdown {
            width: 100% !important;
          }
        }
      }
  }
}

@media screen and (max-width: 550px){
  .chart-data {
  .widget-range-wrapper{
      .widget-range {
        .dropdown {
           width: 100% !important;
        }
      }
    }
  }
}

@media screen and (max-width: 420px){
  .chart-data {
    .widget-range-wrapper {
      .analytics-text{
        width: 100% !important;
        display: table;
      }
      .widget-range,.edit-text {
        display: table !important;
        width: 50% !important;
      }
    }
  }
}
</style>
