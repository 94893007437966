<template>
  <b-tab
    :active="storeFront.index == 1"
  >
    <template #title>
      <span class="fi-icon">
        <img
          :src="require(`@/assets/images/icons/customer/${storeFront.icon}.png`)"
        >
      </span>
      {{ storeFront.title }}
    </template>
    <validation-observer ref="storeObserverTab">
      <b-row>
        <b-col
          v-for="(value, key) in storeFront.data"
          :key="key"
          cols="4"
        >
          <div class="input-group-wrapper">
            <h1 class="tab-content-title">
              {{ months[key-1] }}
            </h1>

            <validation-provider
              #default="{ errors }"
              :name="months[key-1]"
              rules="required|integer"
            >
              <b-input-group>
                <b-form-input
                  v-model="storeFront.data[key]"
                  placeholder="$0 CAD"
                  :disabled="!localFormEnabled"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </div>
        </b-col>
      </b-row>
      <div class="save-modal-button">
        <input
          type="submit"
          value="Save"
          class="btn save-button"
          @click="saveStore"
        >
      </div>
    </validation-observer>
  </b-tab>
</template>

<script>
import {
  required,
  integer,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    storeFront: {
      type: Object,
      require: true,
      default() {
        return {}
      },
    },
    formEnabled: {
      type: Boolean,
      require: true,
      default() {
        return true
      },
    },
  },
  data() {
    return {
      localFormEnabled: this.formEnabled,
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    }
  },
  watch: {
    formEnabled(newVal) {
      this.localFormEnabled = newVal
    },
  },
  methods: {
    saveStore() {
      this.$refs.storeObserverTab.validate().then(success => {
        if (success) {
          this.$emit('saveStore', this.storeFront)
        }
      })
    },
  },
}

</script>
