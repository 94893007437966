<template>
  <b-card>

    <b-row>
      <b-col cols="8">
        <b-row class="mileage-data-wrapper">
          <b-col
            cols="9"
            class="data"
          >
            Apollo Pro Testers
          </b-col>
          <b-col
            cols="3"
            class="text"
          >
            <b-badge
              v-b-tooltip="`${ (proTestersData && proTestersData.avg_km) ? parseInt(proTestersData.avg_km).toFixed(2) : '0' } km logged`"
              variant="light-success"
            >
              {{ proTestersData ? `${proTestersData.percentage}%` : '0' }}
            </b-badge>
          </b-col>
        </b-row>
        <b-row class="mileage-data-wrapper">
          <b-col class="data">
            {{ (proTestersData && proTestersData.avg_km) ? proTestersData.avg_km.toFixed(2) : 0 }}/500km
          </b-col>
          <b-col class="text text-gray">
            Avg. per user
          </b-col>
        </b-row>
        <b-row class="mileage-data-wrapper">
          <b-col class="data">
            {{ proTestersData && proTestersData.completed_customers ? proTestersData.completed_customers : 0 }}/{{ proTestersData && proTestersData.total_customers ? proTestersData.total_customers : 0 }}
          </b-col>
          <b-col class="text text-gray">
            Users completed all
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4" class="text-right">
        <b-row>
          <b-col cols="12" lg="6">
          <b-form-select
            v-model="selectedCountry"
            :options="countryOptions"
            @change="getProTesters"
          />
          </b-col>
          <b-col cols="12" lg="6">
          <b-form-select
            v-model="selectedScooter"
            :options="scootersOptions"
            @change="getProTesters"
          />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue'
import moment from 'moment'
export default {
  components: {
    BFormSelect,
  },
  created() {
    // listen to event from app.vue page
    this.$eventHub.$on('selectedPageRangeChanged', this.getProTesters)

  },
  beforeDestroy() {
    // ignore to event from app.vue page
    this.$eventHub.$off('selectedPageRangeChanged', this.getProTesters)
  },
  data() {
    return {
      selectedCountry: null,
      selectedScooter: null,
      countryOptions: [
        { value: null, text: 'All' },
        { value: 'usa', text: 'USA' },
        { value: 'ca', text: 'Canada' },
      ],
      scootersOptions: [],
      proTestersData: {
        percentage : 0,
        avg_km: 0,
        completed_customers: 0
      },
      showPercentage: false,
      loading: false,
    }
  },
  mounted() {
    this.loadScootersTypes();
    this.getProTesters()
  },
  methods: {
    loadScootersTypes() {
      this.$http.get(this.$resources.firmwares.scooterTypes).then(response => {
        const { data: { data } } = response
        this.scootersOptions = data.rows.map(element => ({ value: element.type, text: element.type }))
        this.scootersOptions.unshift({ value: 'All', text: 'All' })
        this.selectedScooter = 'All'
      })
    },
    getProTesters(showLoader = true,period = null) {
      let nparams = {}


        
      if(period == null) {
        period =  this.$store.state.menuConfig.timePickerSelectorDate ? this.$store.state.menuConfig.timePickerSelectorDate : null
      }
      if(typeof period === 'object' ) {
            nparams  = period ? period : {} 
          }else {
            const start_date = moment(`01/01/${period}`).startOf('year').format('YYYY-MM-DD')
            const end_date = moment(`01/01/${period}`).endOf('year').format('YYYY-MM-DD')
            nparams = period !== 'all-time' ? { start_date, end_date } : null
          }

          nparams.country = this.selectedCountry
          nparams.scooter = this.selectedScooter

      
      this.$http.get(this.$resources.admins.proTesters, { params: nparams }).then(response => {
        const { data: { status, data } } = response
        if (status === 200) {
           this.proTestersData = {...this.proTestersData , ...data }
        } else {
          this.proTestersData = null
        }
      })
    }
  }
}
</script>

<style lang="scss">
.mileage-data-wrapper {
    font-family: Montserrat;
     margin-bottom: 5px !important;
     &:first-child {
        margin-bottom: 10px !important;
     }

    .data {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
    }
    .text {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: right;

        .badge{
            cursor: pointer;
            &.badge-light-success {
                background: #BCDBBC !important;
                color: #151210 !important;

            }

            font-size: 14px;
            font-weight: 600;
            line-height: 19px;
            letter-spacing: 0px;
            text-align: center;

        }
        &.text-gray {
            color: #9A8781;
        }
    }
}




/** media query */

@media screen and (max-width: 1440px) {
  .mileage-data-wrapper {
       &:first-child {
          margin-bottom: 0 !important;
       }
  }
}
</style>
