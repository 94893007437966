<template>
  <div class="store-tab">
    <b-modal
      id="stores-targets-modal"
      ref="storesTargetsModal"
      hide-header
      hide-footer
      centered
      size="lg"
      @hidden="checkReload"
      @show="resetModelChanges"
    >
      <div class="stores-targets-modal-content">
        <div class="stores-targets-modal-header">
          <h1 class="title">
            Store targets for {{ moment().format('YYYY') }}
          </h1>
          <div
            class="closer"
            @click="closeModal"
          >
            X
          </div>
        </div>
        <b-tabs fill>
          <StoreForm
            v-for="(storeForm,index) in storesFormData"
            :key="index"
            :store-front="storeForm"
            :form-enabled="formEnabled"
            @saveStore="saveStore"
          />

        </b-tabs>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import Moment from 'moment'
import StoreForm from './StoreForm.vue'

export default {
  components: {
    BModal,
    StoreForm,
  },
  props: {
    showStoresModal: {
      type: Boolean,
      require: true,
      default() {
        return false
      },
    },
    storesFormData: {
      type: Object,
      require: true,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      moment: Moment,
      formEnabled: true,
      modelChanges: false,
    }
  },
  watch: {
    showStoresModal() {
      this.$refs.storesTargetsModal.show()
    },
  },

  methods: {
    resetModelChanges() {
      this.modelChanges = false
    },
    checkReload() {
      if (this.modelChanges) this.$emit('reloadSalesTargetsCharts')
    },
    saveEntireForm() {
      this.$refs.storesTargetsModal.hide()
    },
    closeModal() {
      this.$refs.storesTargetsModal.hide()
    },

    saveStore(store) {
      const monthData = []
      Object.keys(store.data).forEach(key => {
        monthData.push({
          month: key,
          amount: store.data[key],
        })
      })
      this.formEnabled = false

      this.$http.put(this.$resources.targets.stores.update.replace(':store', store.id), {
        store: store.id,
        targets: monthData,
      }).then(response => {
        const { data: { status } } = response
        if (status === 200) {
          this.formEnabled = true
          this.modelChanges = true
        }
      })
    },
  },
}
</script>
<style lang="scss">
#stores-targets-modal {
  .stores-targets-modal-content {
    padding: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        font-size: #1D1D1D;
        margin-bottom: 10px;
    }

        .nav-fill {
          width: 100%;
          display: inline-flex;
          background-color:#F5F3F2;
          li {
            a {
              border-radius: 10px;
              border: 1px solid transparent;
              font-weight: 500;
              &.active {
                  background-color: #E6E6E6 !important;
                  border: 1px solid rgba(48, 48, 48, 0.04);
                }
             }
           }
        }
    .tab-content {
        .tab-pane {
                .input-group-wrapper {
                    margin-bottom: 12px;
                    .tab-content-title {
                        padding-left: 3px;
                        font-weight: 500;
                        font-size: 14px;
                        color:#1D1D1D;
                        margin-bottom: 3px;
                    }
                }
                input {
                    color: #1D1D1D;
                    font-weight: 500;
                }

            }
    }
    .save-modal-button {
        display: flex;
        justify-content: flex-end;
        margin: 12px 0 0 0;
        .save-button {
            padding: 8px 18px;
            background: #E7E7E7;
            color: #1D1D1D;
            border-radius: 5px;
            float: right;
            &:hover {
                padding: 8px 18px;
                background-color:lightgray;
                border-radius: 5px;
                float: right;
            }
        }
    }

    .stores-targets-modal-header {
        display: flex;
        justify-content: space-between;
        .closer {
            cursor: pointer;
            font-family: cursive;
            font-weight: 500;
            font-size: 17px;
            color: #C6C6C6;
            width: 20px;
            text-align: center;
            height: 24px;
            margin-top: -5px;
            &:hover {
                color: #68627C
            }
        }
        .title {
          margin-bottom: 20px;
        }
    }

    .fi-icon {
      margin-right: 5px;
    }
  }
}
</style>
