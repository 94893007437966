<template>
  <b-col class="placeholder-col">
    <b-card class="placeholder-card">
      placeholder
    </b-card>
  </b-col>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.placeholder-col {
    height: 95% !important;
    .placeholder-card {
        height: 101% !important;
        display: flex;
      .card-body {
          display: flex;
          height: 100% !important;
          justify-content: center;
          align-items: center;
      }
    }
}
</style>